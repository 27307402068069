import * as Yup from 'yup';

export const signUpSchema = Yup.object({
    username: Yup.string()
        .min(3)
        .max(25)
        .required('Please enter your name')
     
        .test(
            'no-special-characters',
            'Special characters are not allowed in the name',
            (value) => !/[!@#$%^&*(),.?":{}|<>]/.test(value)
        )
        .test(
            'no-consecutive-characters',
            'Consecutive repeating characters are not allowed in the username',
            (value) => !/(.)\1{2,}/.test(value)
        )
        .test(
            'allow-only-middle-space',
            'Spaces are allowed only in the middle of two strings',
            (value) => /^(?!.*\s{2,})(?!^\s|\s$)[\w\s]+$/.test(value)
        ),

           // email validation
           email: Yup.string()
           .email()
           .required('Please Enter Email Address')
           .test('example@example.com',
                 'Not valid email',
                 (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)

           ),

           //password validation

           pass1: Yup.string()
           .min(6)
           .matches(
               /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
               'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
           )
           .test(
               'no-consecutive-characters',
               'Consecutive characters are not allowed in the password',
               (value) => !/(.)\1/.test(value)
           )
           .required('Please enter your password'),
           
        
           // URL validation
           url: Yup.string().url('Please enter a valid URL').required('Please enter a URL'),
     

          // confirm password

          cpass: Yup.string().label('confirm password')
          .required().oneOf([Yup.ref('pass1'), null]
          , 'Passwords must match'),

          //phone number

          phone: Yup.string()
        //   .matches(/^[0-9]{10}$/, 'Mobile number must be 10 digits')
          .required('Mobile number is required'),


    
            captcha: Yup.string()
              .matches(/^[a-zA-Z0-9]{6}$/, 'Invalid captcha format')
              .required('Captcha is required'),
          })
          






        
           
          
