import React, { useEffect, useState } from "react";
import Registration from "./Components/Registration";
import { Route, Routes } from "react-router-dom";
import Welcome from "./Components/Welcome";



function App() {






  return (


    <div className="App">
  
    
      <Routes>
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/" element={<Registration />} />
      </Routes>
     

  



    </div>
  )};


export default App;
