import React from 'react'

const Welcome = (props) => {
  return (
    <div>
<h2>{props.ab}c</h2>
  

    <h1>Thank For Registration</h1>

   </div>
  )
}

export default Welcome