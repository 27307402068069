  import React,{useState} from 'react'
  import { useFormik } from "formik"
   import "./Registration.css";
  import { signUpSchema } from './Validate';
  import PhoneInput from 'react-phone-input-2';
  import 'react-phone-input-2/lib/style.css'
  import Welcome from './Welcome';
  import { useNavigate } from 'react-router-dom'; 



  const generateRandomCaptcha = () => {

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const captchaLength = 6;

    let captcha = '';
    for (let i = 0; i < captchaLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      captcha += characters.charAt(randomIndex);
    }

    return captcha;
  };

  const initialValues = {
    username: "",
    email: "",
    pass1: "",
    cpass: "",
    phone: "",
    url: "",
    uploadimage: "",
    uploardocument: "",
    captcha: "",
  }




  const Registration = () => {
    const [generatedCaptcha, setGeneratedCaptcha] = useState(generateRandomCaptcha());
    const [formSubmitted, setFormSubmitted] = useState(false);   
    const navigate = useNavigate();   


    

    const {
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
    } = useFormik({
      initialValues,
      validationSchema: signUpSchema,
      onSubmit: (values) => {
        console.log(values);



        try {
       
          navigate('/welcome'); 
        } catch (error) {

          console.error('Form submission error:', error);
        }
      },

      context: {
        generatedCaptcha,
      },
    });

    const handleRefreshCaptcha = () => {
      const newCaptcha = generateRandomCaptcha();
      setGeneratedCaptcha(newCaptcha);
      setFieldValue('captcha', ''); // Clear the input field when refreshing
    };


    if (formSubmitted) {
      // Render the Welcome component or navigate to a new route/page
      return <Welcome />;
    }

    
    return (

<center>




      <form onSubmit={handleSubmit}>

      
      <div className="container">

        <div className="brand-title">Registration</div>
        <div className="inputs">
          <label htmlFor='username'>USER-NAME</label>
          <input type="text" placeholder="Please Enter userName"
          name='username' value={values.username} onChange={handleChange} 
          onBlur={() => handleBlur('username')}  />


{touched.username && errors.username && <div className="error">{errors.username}</div>}




          <label htmlFor='email'>EMAIL</label>
          <input type="email" placeholder="example@test.com" 
          name='email' value={values.email} onChange={handleChange}
          onBlur={handleBlur} />
          {touched.email && errors.email && <div className="error">{errors.email}</div>}
          <label htmlFor='pass1'>PASSWORD</label>
          <input type="password" placeholder="Min 6 charaters long" 
          name='pass1' value={values.pass1} onChange={handleChange}
          onBlur={handleBlur} />
            
            {touched.pass1 && errors.pass1 && <div className="error">{errors.pass1}</div>}

          <label htmlFor='cpass'> CONFIRM-PASSWORD</label>
          <input type="password" placeholder="Min 6 charaters long" 
          name='cpass' value={values.cpass} onChange={handleChange} 
          onBlur={handleBlur} />
          

          {touched.cpass &&  errors.cpass && <div className="error">{errors.cpass}</div>}

          <label htmlFor='phone'>PHONE</label>



<PhoneInput
  country={'us'}
  value={values.phone}  
  name='phone'// Use values.phone instead of this.state.phone
  onChange={phone => setFieldValue('phone', phone)}  // Use setFieldValue to update Formik state
/>


  {touched.phone && errors.phone && <div className="error">{errors.phone}</div>}

          <label htmlFor='url'>URL</label>
          <input type="text" placeholder="Enter Url" name='url'
          value={values.url} onChange={handleChange} onBlur={handleBlur} />
    {touched.url &&  errors.url && <div className="error">{errors.url}</div>}
          <label htmlFor='img'>UPLOARD-IMAGE</label>
          <input type='file' placeholder="Please Upload" 
          name='img' accept='image/*' value={values.img}  
          onChange={handleChange} 
          onBlur={handleBlur}/>
          <label htmlFor='doc'>UPLOARD-DOCUMENT</label>
          <input type="file" placeholder="Please Upload" name='doc' 
          value={values.doc}  onChange={handleChange} 
          onBlur={handleBlur}
          accept=".doc, .docx, .pdf, .txt"
          />


  <div className="captcha-display" >{generatedCaptcha}</div>
  <button type="button" onClick={handleRefreshCaptcha} className='refreshBtn'>
              Refresh
            </button>
            <label htmlFor="captcha">CAPTCHA</label>
  <input
    type="text"
    placeholder="Please Enter Captcha"
    name="captcha"
    value={values.captcha}
    onChange={handleChange}
    onBlur={handleBlur}
  />
  {touched.captcha && errors.captcha && <div className="error">{errors.captcha}</div>}





      
          <button type="submit">LOGIN</button>
        </div>
    
      </div>
      </form>
      </center>


    )
  }

  export default Registration